import { type FC } from 'react';
import { Modal } from 'flowbite-react';
import AnalyticForm from 'components/molecules/Analytics/AnalyticForm';
import RegistrationProcess from 'components/molecules/Analytics/RegistrationProcess';

type AnalyticRegistrationModalProps = {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
};

const AnalyticRegistrationModal: FC<AnalyticRegistrationModalProps> = ({
  openModal,
  setOpenModal,
}) => {
  return (
    <Modal
      dismissible
      size="3xl"
      show={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <Modal.Header>アナリティクスを登録</Modal.Header>
      <Modal.Body>
        <RegistrationProcess />
        <AnalyticForm />
      </Modal.Body>
    </Modal>
  );
};

export default AnalyticRegistrationModal;
